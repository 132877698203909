function trackEvent(event) {
  if (typeof fathom !== 'undefined') {
    fathom.trackEvent(event);
  } else {
    console.log('Fathom is not loaded');
  }
}

function openVideoTheather() {
  const videoTheather = document.getElementById('video-theather');
  const body = document.body;
  const youtubeVideo = document.getElementById('youtube-video');

  trackEvent('openVideoTheather'); 
  videoTheather.classList.remove('hidden');
  body.classList.add('overflow-hidden');
  youtubeVideo.src = "https://www.youtube.com/embed/m-JTAjQlR-M?si=xZJCBXSTzKTCR-Zs&amp;controls=1&amp;autoplay=1";
}

function closeVideoTheather() {
  const videoTheather = document.getElementById('video-theather');
  const body = document.body;
  const youtubeVideo = document.getElementById('youtube-video');

  videoTheather.classList.add('hidden');
  body.classList.remove('overflow-hidden');
  youtubeVideo.src = "";
}
